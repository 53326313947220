import {Box, Button, CircularProgress, Fab, IconButton, InputAdornment, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, Stack, TextField, ThemeProvider, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {ctx, PAGES} from "../codes/Context";
import {Wrapper} from "./webparts/Wrapper";
import SendIcon from '@mui/icons-material/Send';
import {chatsPool} from "../codes/ChatsPool";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {mesTheme} from "../Theme";
import {useEffect, useState, useRef} from "react";
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import {ActionSate, FetchDirection, RestEnvironment, TypeOfMessages} from "../environment";
import {ActionPanel} from "./panels/ActionPanel";
import {pool} from "../codes/Pool";
import {StoredMessage} from "../codes/DataCord";
import {base64ToFile, compressImage, isnull, subsctruct} from "../utils";
import {fileReplyCSS, meReplyCSS, notMeReplyCSS, QuotedBox, varNoMeBackgroundDarker} from "./webparts/Decorator";
import CancelIcon from '@mui/icons-material/Cancel';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import AddLinkIcon from '@mui/icons-material/AddLink';
import WebcamDialog from "./panels/WebcamDialog";
import ImageIcon from '@mui/icons-material/Image';


const MessagesPage = observer(() => {

    const [lightLoading, setLightLoading] = useState<{show: boolean, text?: string}>({show: false});
    const [messageText, setMessageText] = useState<string>("");
    const [showUploadButton, setShowUploadButton] = useState<boolean>(false)
    const [file, setFile] = useState<File | null>(null)
    const [prepareMakeLoading, setprepareMakeLoading] = useState<boolean>(false);

    const refFile = useRef<HTMLInputElement>(null);


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuopen = Boolean(anchorEl);
    const menuHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const menuHandleClose = () => {
        setAnchorEl(null);
    };

    const [camera, setCamera] = useState<boolean>(false);

    function isOnCamera() 
    {
        return camera;
    }

    function restateCamera(state:boolean) 
    {
        setCamera(state);
    }
    function reset() {
        pool.highlight = undefined;
        setFile(null);
        setMessageText("");
    }

    // function captureFile(captFile:File) 
    // {
    //     setFile(captFile);
    // }
    // Добавленно сжатие картинки
    async function captureFile(captFile: File) {
        let file = captFile;

        try {
            const compressedBlob = await compressImage(file);
            file = new File([compressedBlob], file.name, { type: file.type });
        } catch (error) {
            console.error("Ошибка сжатия изображения:", error);
        }


        setFile(file);
      }

    function poolSend(messageVal: string, replyid: number | undefined,
        docid: number | undefined, docds: string | undefined, replyVal: string | undefined
    ) {
        pool.sendMessage(
            chatsPool.CurrentID,
            TypeOfMessages.PLAINTEXT,
            messageVal,
            replyid,
            undefined, undefined,
            docid, docds,
            replyVal
        );
    }

    function send() {

        if ((messageText !== "") || (file != null)) {
            const messageVal = (messageText.length > 0) ? messageText : "Отправка файла";
            const replyid = pool.highlightID;
            const replyVal = pool.highlightText;

            if (file != null) {
                setprepareMakeLoading(true);
                pool.uploadFile([file], [["persist", "0"], ["userid", String(ctx.usid)]])
                    .then((doc: {docds: string, docid: number}) => {
                        setprepareMakeLoading(false);
                        poolSend(messageVal, replyid, doc.docid, doc.docds, replyVal);
                        reset();

                    })
                    .catch(error => {
                        setprepareMakeLoading(false);
                        ctx.makeAppDialog(true, false, "Ошибка загрузки файла",
                            error.message || "error to ");
                        ctx.showAppDialog();
                    }
                    )
            } else {
                poolSend(messageVal, replyid, undefined, undefined, replyVal);
                reset();
            }
        }
    }




    useEffect(() => {
        ctx.AppState = {state: ActionSate.LOADING};
        pool.highlight = undefined;
        setFile(null);
        pool.loadMessages(chatsPool.CurrentID, undefined, FetchDirection.LAST, true,
            (state: ActionSate, text?: string, count?: number) => {
                ctx.AppState = {state: state, text: text};
                setShowUploadButton(isnull(count, 0) >= RestEnvironment.poolSize)
            });
    },
        [chatsPool.CurrentID]
    );


    // const onInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const files = e.target.files; // Get the FileList
    //     if ((files != null) && (files.length > 0)) {
    //         setFile(files[0]);
    //     }
    // };
    // Добавленно сжатие картинки
    const onInputFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files; 
      
        if (files && files.length > 0) {
          let file = files[0]; 
      
          
          if (file.type.startsWith("image/")) {
            try {
              const compressedBlob = await compressImage(file); 
              file = new File([compressedBlob], file.name, { type: file.type }); 
            } catch (error) {
              console.error("Ошибка сжатия изображения:", error);
            }
          }
          setFile(file); 
        }
      };

    return (
        <>

            <Fab color="primary" aria-label="add" size="medium"
                sx={{

                    position: "absolute",
                    top: 16,
                    left: 10,
                    opacity: "60%"
                }} onClick={() => {
                    pool.highlight = undefined;
                    ctx.currentPage = PAGES.PAGE_CHATS
                }} >
                <KeyboardDoubleArrowLeftIcon />
            </Fab>

            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    minHeight: 0,
                    px: 2,
                    py: 3,
                    overflowY: 'auto',
                    flexDirection: 'column-reverse',
                    mb: 1
                }}
            >

                {pool.Display(chatsPool.CurrentID).map((message: StoredMessage) => {
                    return (
                        <>
                            <Wrapper message={message} />
                        </>
                    )
                })

                }

                {showUploadButton && (
                    <Box
                        sx={{
                            // height: "100dvh",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: "center", justifyContent: "center"
                        }}
                    >
                        <Button variant="text" color="secondary"
                            sx={{fontSize: 13}}
                            onClick={() => {
                                pool.loadMessagesBack(chatsPool.CurrentID, (state: ActionSate, text?: string, count?: number) => {
                                    if (state === ActionSate.ERROR) {
                                        setLightLoading({show: false, text: text});
                                    }
                                    else {
                                        setLightLoading({show: false});
                                        setShowUploadButton(isnull(count, 0) >= RestEnvironment.poolSize);
                                    }
                                });
                                setLightLoading({show: true});
                            }}>
                            {(lightLoading.show) ?
                                (<><CircularProgress sx={{zoom: "50%"}} />
                                    Загрузка ранних сообщений...</>) :
                                (<><KeyboardDoubleArrowUpIcon />
                                    {(lightLoading.text) ? (<>{lightLoading.text}</>)
                                        : (<>Загрузить ранние сообщения</>)}
                                </>)

                            }

                        </Button>
                    </Box>
                )}

            </Box>
            {chatsPool.Editable &&
                (
                    <Box display="flex" flexDirection="column" >

                        {(pool.highlight) &&
                            (<Box display="flex" flexDirection="row" sx={{}}>
                                <QuotedBox
                                    sxx={{
                                        ...pool.highlightIsMe ? meReplyCSS : notMeReplyCSS
                                    }}
                                    alignItems="baseline"
                                    endDecorator={(
                                        <IconButton sx={{transform: 'scale(0.8)'}}
                                            onClick={() => {pool.highlight = undefined}} ><CancelIcon sx={{color: varNoMeBackgroundDarker}} />
                                        </IconButton>)}>
                                    <>{subsctruct("↩ " + pool.highlightText || "", 46)}</>
                                </QuotedBox>
                            </Box>
                            )
                        }

                        {(file != null) &&
                            (<Box display="flex" flexDirection="row" sx={{}}>
                                <Stack direction={"column"}>
                                    <QuotedBox
                                        sxx={{
                                            ...fileReplyCSS
                                        }}
                                        alignItems="baseline"
                                        endDecorator={(
                                            <IconButton sx={{transform: 'scale(0.8)'}}
                                                onClick={() => {setFile(null);}} ><CancelIcon sx={{color: varNoMeBackgroundDarker}} />
                                            </IconButton>)}>
                                        <>{subsctruct("📎 " + file.name || "", 46)}</>
                                    </QuotedBox>
                                    {(prepareMakeLoading) &&
                                        <LinearProgress />} </Stack>
                            </Box>
                            )
                        }

                        <Stack direction="row">

                            <TextField
                                disabled={prepareMakeLoading}
                                id="filled-multiline-flexible"
                                //label="Сообщение"
                                multiline
                                maxRows={4}
                                variant="outlined"
                                sx={{width: "100%", }}
                                onChange={(e) => {
                                    setMessageText(e.target.value);
                                }}
                                value={messageText}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" >

                                            <div>

                                                <IconButton
                                                    id="basic-button"
                                                    aria-controls={menuopen ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={menuopen ? 'true' : undefined}
                                                    onClick={menuHandleClick}><AddLinkIcon color="primary"

                                                    /></IconButton>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={menuopen}
                                                    onClose={menuHandleClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <MenuItem onClick={

                                                        () => {
                                                            setAnchorEl(null);
                                                            if (refFile.current?.accept!=null)
                                                                {
                                                                    (refFile.current as any).accept="*/*" 
                                                                }
                                                            refFile.current?.click();

                                                        }

                                                    }>
                                                        <ListItemIcon>
                                                            <AttachFileIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Файл" />
                                                    </MenuItem>
                                                    <MenuItem onClick={
                                                        ()=>{
                                                            setAnchorEl(null);
                                                            restateCamera(true);
                                                        }
                                                    }>
                                                        <ListItemIcon>
                                                            <CameraAltIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Камера" />
                                                    </MenuItem>
                                                    <MenuItem onClick={

                                                        () => {
                                                            setAnchorEl(null);
                                                            if (refFile.current?.accept!=null)
                                                            {
                                                                (refFile.current as any).accept=".png, .jpg, .jpeg, .gif.,.tiff,image/gif,image/jpeg,image/png,image/tiff" 
                                                            }
                                                       
                                                            refFile.current?.click();

                                                        }

                                                    }>
                                                        <ListItemIcon>
                                                            <ImageIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Картинка" />
                                                    </MenuItem>
                                                </Menu>
                                            </div>

                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={() => {
                                                send();
                                            }}><SendIcon color="primary" /></IconButton>
                                        </InputAdornment>
                                    )
                                }}

                            />


                        </Stack>
                    </Box>
                )}
            <input type="file" ref={refFile} style={{display: 'none'}} multiple={false}
                onChange={((e: React.ChangeEvent<HTMLInputElement>) => onInputFile(e))} />

            <WebcamDialog isOnCamera={isOnCamera} restateCamera={restateCamera} captureFile={captureFile}/>
        </>
    );
}

);
export {MessagesPage}