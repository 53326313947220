import imageCompression from "browser-image-compression";

function isStringTestJson(a: any) {
    try {
        JSON.parse(a);
    } catch (e) {
        return false;
    }
    return true;
}

function castJsonToStringOrDes(a: any): string {
    if (isStringTestJson(a)) {
        return JSON.stringify(a);
    }
    else {
        let str: string = "";
        for (const key in a) {
            str = str + key + "=" + a[key];
        }
        return str;
    }
}

function dateReFormat(value: string, inf: string, outf: string): string {
    let y = inf.indexOf("yyyy"), ysize = 4;
    if (y === -1) {
        y = inf.indexOf("yy");
        ysize = 2;
    }
    let m = inf.indexOf("mm");
    let d = inf.indexOf("dd");
    let h = inf.indexOf("HH");
    let mi = inf.indexOf("MM");
    let s = inf.indexOf("SS")
    if (y > -1) {
        outf = outf.replace(((ysize > 2) ? "yyyy" : "yy"), value.substring(y, y + ysize));
    }
    if (m > -1) {
        outf = outf.replace("mm", value.substring(m, m + 2));
    }
    if (d > -1) {
        outf = outf.replace("dd", value.substring(d, d + 2));
    }
    if (h > -1) {
        outf = outf.replace("HH", value.substring(h, h + 2));
    }
    if (mi > -1) {
        outf = outf.replace("MM", value.substring(mi, mi + 2));
    }
    if (s > -1) {
        outf = outf.replace("SS", value.substring(s, s + 2));
    }
    return outf;
}

//Convert date to epoch or unix timestamp in TypeScript
function dateToMs_String(data: Date): string {
    let date_String: string = data.getTime().toString();
    return date_String;
}

function DateToEpoch(date: Date): number {
    return Math.floor(date.getTime() / 1000);
}



function UTCTodate(data: number, nodif: boolean = true): string {
    function plusZero(v: number) {
        return ((v < 10) ? "0" : "") + String(v);
    }
    var utc = new Date(data * 1000);
    var offset = utc.getTimezoneOffset();
    var local = new Date(utc.getTime() + ((!nodif) ? offset * 60000 : 0));
    return plusZero(local.getDate()) + "." +
        plusZero(local.getMonth() + 1) + "  " +
        plusZero(local.getHours()) + ":" +
        plusZero(local.getMinutes()) 
        // + plusZero(local.getSeconds())
}



function subsctruct(value: string, count: number) {
    if (value === undefined) value = "";
    if (value.length > count) {
        value = value.substring(0, count - 1) + "...";
    }
    return value;
}

function isnull(value: any | undefined, def: number) {
    if (value === undefined) return def;
    return Number(value)
}


function base64ToFile (base64String:string, fileName:string) {
    // Remove the data URL scheme if present
    const byteString = atob(base64String.split(',')[1]); // Decode Base64 string
    const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type

    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        byteNumbers[i] = byteString.charCodeAt(i);
    }
    
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], fileName, { type: mimeString }); // Create and return the File object
};

const isImage = (name: string): boolean => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const extension = name.split('.').pop();
    return extension ? imageExtensions.includes(extension.toLowerCase()) : false;
};

async function compressImage(file: File) {
  
    if (!file.type.startsWith("image/")) {
      console.warn("Выбранный файл не является изображением.");
      return file; 
    }
  
    const options = {
      maxSizeMB: 1, 
      maxWidthOrHeight: 1024, 
      useWebWorker: true,
    };
  
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Ошибка сжатия изображения:", error);
      return file; 
    }
  }

export {compressImage, isImage, isStringTestJson, castJsonToStringOrDes, dateReFormat, UTCTodate, subsctruct, DateToEpoch, isnull,base64ToFile,dateToMs_String}

